<template>
  <component :is="commonComponent" id="privacy" classs="privacy ">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-head">
        Introduction
      </div>

      <div class="common-content-row">
        This Privacy Policy (hereinafter referred to as &quot;Policy&quot;) describes how we
        collect, use, and protect your
        personal information. By accessing and using {{ domainConfig["IAmURL"] }} (hereinafter
        referred to as
        &quot;the Website&quot;),
        you agree to the terms outlined in this Policy. If you do not agree with these terms, please
        do not use the Website.
      </div>

      <div class="common-content-head">
        Information Collection
      </div>

      <div class="common-content-row">
        1.1 Non-Personal Identification Information: When you visit the Website, we may
        automatically collect certain
        non-personal identification information such as your IP address, browser type, access times,
        and pages viewed. This
        information is used to improve our Website and services.
      </div>

      <div class="common-content-row">
        1.2 Personal Identification Information: Since the Website does not require user
        registration or login, we do not
        actively collect any personal identification information.
      </div>

      <div class="common-content-head">
        Information Usage
      </div>

      <div class="common-content-row">
        2.1 Service Improvement: We use the collected non-personal identification information to
        analyze user behavior and
        improve the content and functionality of the Website.
      </div>

      <div class="common-content-row">
        2.2 Personalized Recommendations: Based on your browsing history and preferences, we may
        recommend relevant novel
        content to you.
      </div>

      <div class="common-content-head">
        Information Sharing
      </div>

      <div class="common-content-row">
        We do not sell, trade, or rent your personal information to third parties. We may disclose
        your information in the
        following circumstances:
      </div>

      <div class="common-content-row">
        <ul>
          <li>To comply with legal regulations or legal processes.</li>
          <li>
            To protect our rights, property, or safety, or the rights, property, or safety of
            other users.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        Information Security
      </div>

      <div class="common-content-row">
        We take reasonable technical and organizational measures to protect your information from
        unauthorized access, use,
        or disclosure. However, internet transmission is not completely secure, and we cannot
        guarantee the absolute security
        of your information.
      </div>

      <div class="common-content-head">
        Third-Party Links
      </div>

      <div class="common-content-row">
        The Website may contain links to third-party websites. We are not responsible for the
        privacy policies or content of
        these third-party websites. We recommend that you read the privacy policies of these
        websites when you visit them.
      </div>

      <div class="common-content-head">
        Children&#39;s Privacy
      </div>

      <div class="common-content-row">
        The Website has no age restrictions, but we do not knowingly collect personal information
        from children under the age
        of 13. If we discover that we have inadvertently collected personal information from a child
        under 13, we will take
        steps to delete such information as soon as possible.
      </div>

      <div class="common-content-head">
        Changes to the Privacy Policy
      </div>

      <div class="common-content-row">
        We reserve the right to modify this Privacy Policy at any time. The modified Policy will be
        effective immediately
        upon posting on the Website. Your continued use of the Website constitutes your acceptance
        of the modified Policy.
      </div>

      <div class="common-content-head">
        Contact Us
      </div>

      <div class="common-content-row">
        If you have any questions about this Privacy Policy or need further information, please
        contact us at:
      </div>

      <div class="common-content-row">
        Email: {{ domainConfig['IAMEMAIL'] }}
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Privacy Policy | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: `Discover a world of AI-generated novels and stories at ${this.domainConfig["IAmURL"]}. Dive into unique, imaginative tales created by advanced artificial intelligence. Perfect for readers seeking fresh and innovative content.`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "domainConfig"

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>